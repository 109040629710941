import { ColumnTable, Icon, TextAreaInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormFields, CourseFormMode } from '../types';

export const useColumnsLearningResults = ({
  mode,
  onDelete,
}: {
  mode: CourseFormMode;
  onDelete: (index: number) => void;
}) => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const { setValue, getValues } = useFormContext<CourseFormFields>();

  return useMemo<ColumnTable<any>[]>(() => {
    return [
      {
        columnName: 'code',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center font-weight-bold',
        headerText: t(`learningResultCode`),
      },
      {
        columnName: 'description',
        thClassName: 'text-center',
        tdClassName: 'text-center p-0 ',
        headerText: t(`learningResultDescription`),
        cellFormat: ({ index }) => {
          const description = getValues(`learningResults.${index}.description`);
          const canEdit = getValues(`learningResults.${index}.canEdit`);
          return (
            <div className="d-flex align-items-center" style={{ height: '10' }}>
              <TextAreaInput
                height="10"
                className="w-100"
                label=""
                onChange={(e) =>
                  setValue(
                    `learningResults.${index}.description`,
                    e.target.value,
                  )
                }
                value={description}
                name={`learningResults.${index}.description`}
                disabled={mode === CourseFormMode.WATCH || !canEdit}
              />

              <div
                className={
                  mode !== CourseFormMode.WATCH ? 'cursor-pointer' : ''
                }
                onClick={() => {
                  if (mode !== CourseFormMode.WATCH) {
                    setValue(`learningResults.${index}.canEdit`, !canEdit);
                  }
                }}
              >
                <Icon
                  size="15"
                  name="edit"
                  className="ml-2"
                  color={mode === CourseFormMode.WATCH ? 'disabled' : 'primary'}
                />
              </div>
            </div>
          );
        },
      },
      {
        columnName: 'actions',
        width: '30%',
        thClassName: 'text-right pr-5',
        tdClassName: 'text-right pr-5',
        headerText: t(`learningResultAction`),
        cellFormat: ({ index }) => (
          <div
            className={
              mode !== CourseFormMode.WATCH ? 'cursor-pointer mr-5' : 'mr-5'
            }
            onClick={() => {
              if (mode !== CourseFormMode.WATCH) {
                onDelete(index);
              }
            }}
          >
            <Icon
              size="15"
              name="trash"
              color={mode === CourseFormMode.WATCH ? 'disabled' : 'primary'}
            />
          </div>
        ),
      },
    ];
  }, [t, onDelete, mode, getValues, setValue]);
};
