import { Button, showDialogConfirm, Table } from '@octano/global-ui';
import { useCallback, useContext, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../../../components/text';
import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import { TranslationsKeys } from '../../../../../locales/translations';
import { useColumnsLearningResults } from '../../hooks/useColumnsLearningResults';
import { CourseFormContext } from '../../providers/CourseProvider';
import { CourseFormFields, CourseFormMode } from '../../types';
import ModalAddCourseLearning from '../modals/ModalAddCourseLearning';

const LearningResultSection = () => {
  const [
    modalCourseLearningResultOpen,
    setModalCourseLearningResultOpen,
  ] = useState<boolean>(false);

  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const { control, watch } = useFormContext<CourseFormFields>();
  const { mode } = useContext(CourseFormContext);
  const learningResults = watch('learningResults');

  const { remove, append } = useFieldArray({
    control,
    name: 'learningResults',
  });

  const onDelete = useCallback(
    (index: number) => {
      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'warning',
        },
        title: t(`removeLearningResultTitle`),
        subtitle: '',
        btnConfirm: {
          text: t(`removeLearningResultRemoveConfirm`),
          onConfirm: () => remove(index),
        },
        btnClose: {
          text: t(`removeLearningResultRemoveCancel`),
        },
      });
    },
    [remove, t],
  );

  const columns = useColumnsLearningResults({ mode, onDelete });

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-4"
        >
          <SectionTitle text={t(`learningResult`)} />
          {mode !== CourseFormMode.WATCH && (
            <Button
              icon="plus"
              onClick={() => setModalCourseLearningResultOpen(true)}
              size="md"
              text={t(`addLearningResult`)}
            />
          )}
        </Col>
        <Col xs={12}>
          <Table
            columns={columns}
            data={learningResults ?? []}
            noResultsText={
              <TableEmptyContent
                title={t(`titleEmptyCompatibilities`)}
                subtitle={t(
                  `${
                    mode === CourseFormMode.WATCH
                      ? 'watchBodyEmptyCompatibilities'
                      : 'bodyEmptyCompatibilities'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>
      <ModalAddCourseLearning
        isOpen={modalCourseLearningResultOpen}
        toggle={() => setModalCourseLearningResultOpen(false)}
        onConfirm={append}
      />
    </>
  );
};

export default LearningResultSection;
