import { Button, Table, addToast, CheckInput } from '@octano/global-ui';
import {
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  Ref,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import {
  QualitativeAssessmentRangeRow,
  useQualitativeAssessmentRangeColumns,
} from '../hooks/useQualitativeAssessmentRangeColumns';
import { useQualitativeAssessmentRangeConfig } from '../hooks/useQualitativeAssessmentRangeConfig';
import { CommonSectionMethods } from '../interfaces/common-section';

import { useForm, useFieldArray } from 'react-hook-form';
import QualitativeAssessmentRangeModal, {
  QualitativeAssessmentRangeModalMethods,
} from './QualitativeAssessmentRangeModal';
import { updateQualitativeAssessmentRangeConfig } from '../../../../api/requests/settings';
import { QualitativeAssessmentRangeType } from '../interfaces/qualitative-assessment-range.interface';
import React from 'react';

const prefix = 'qualitativeAssessmentRange';

type FormFields = {
  hasRecords: boolean;
  records: QualitativeAssessmentRangeRow[];
};

type QualitativeAssessmentRangeSectionProps = {
  maxRecords?: number;
};

const QualitativeAssessmentRangeSection = (
  { maxRecords = 5 }: QualitativeAssessmentRangeSectionProps,
  ref: Ref<CommonSectionMethods>,
) => {
  const qualitativeAssessmentRangeModalRef =
    useRef<QualitativeAssessmentRangeModalMethods>(null);
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);

  const { isLoading, config, refetch } = useQualitativeAssessmentRangeConfig();

  const { control, reset, watch } = useForm<FormFields>();
  const {
    fields: records = [],
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'records',
  });

  const hasRecords = watch('hasRecords');

  const [isUpdating, setIsUpdating] = useState(false);

  const columns = useQualitativeAssessmentRangeColumns({ onRemove: remove });

  const scores = useMemo(
    () => ({
      min: config?.minScore ?? 1,
      max: config?.maxScore ?? 2,
    }),
    [config],
  );

  const handleValidate = useCallback(async () => {
    if (!hasRecords) {
      return;
    }
    const lastRecord = records[records.length - 1];
    if (!lastRecord || lastRecord.end !== scores.max) {
      addToast({
        icon: 'error',
        text: t(`${prefix}.errorRecordsPending`),
        color: 'danger',
      });
      throw new Error('NOT_VALID_SECTION_VALUES');
    }
  }, [records, scores, t, hasRecords]);

  const handleSave = useCallback(async () => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);

    const recordsToUpdate: QualitativeAssessmentRangeRow[] = records.map(
      (r, i) => ({
        ...r,
        id: !isNaN(r.id) ? r.id : undefined,
        endType:
          i === records.length - 1
            ? QualitativeAssessmentRangeType.Inclusive
            : QualitativeAssessmentRangeType.Exclusive,
      }),
    );

    const { error: updateError } = await updateQualitativeAssessmentRangeConfig(
      hasRecords ? recordsToUpdate : [],
    );

    if (updateError) {
      addToast({
        icon: 'error',
        text: t(`${prefix}.errorSavingChanges`),
        color: 'danger',
      });
    } else {
      // Actualizamos config
      await refetch();

      addToast({
        icon: 'success',
        text: t(`${prefix}.changesSaved`),
        color: 'success',
      });
    }

    setIsUpdating(false);
  }, [isUpdating, refetch, t, records, hasRecords]);

  const openRecordModal = useCallback(() => {
    const lastRecord = records[records.length - 1];
    if (lastRecord) {
      qualitativeAssessmentRangeModalRef?.current?.open({
        minScore: lastRecord.end,
        maxScore: scores.max,
      });
    } else {
      qualitativeAssessmentRangeModalRef?.current?.open({
        minScore: scores.min,
        maxScore: scores.max,
      });
    }
  }, [records, scores]);

  useImperativeHandle(ref, () => ({
    save: handleSave,
    validate: handleValidate,
  }));

  useEffect(() => {
    if (config?.records?.length) {
      reset({ records: config?.records, hasRecords: true });
    } else {
      reset({ records: [], hasRecords: false });
    }
  }, [config, reset]);

  if (!config?.sectionEnabled) {
    return <></>;
  }

  return (
    <>
      <div className="w-100 mt-3">
        <CheckInput
          name="hasRecords"
          label={t(`${prefix}.hasRecords`)}
          control={control}
        />
      </div>
      {!!hasRecords && (
        <>
          <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3 mt-5">
            {t(`${prefix}.title`)}
          </h2>
          <p className="fs-14 text-medium mb-3">{t(`${prefix}.description`)}</p>
          <div className="w-100 d-flex flex-wrap align-items-center mb-4">
            <div
              className="d-flex align-items-center"
              style={{ gap: 5, flex: 0.5 }}
            >
              <span
                className="border border-primary text-success d-flex align-items-center justify-content-center"
                style={{ width: 37, height: 37 }}
              >
                {'[ X'}
              </span>
              <span style={{ maxWidth: 200 }}>
                {t(`${prefix}.legendInclusive`)}
              </span>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ gap: 5, flex: 0.5 }}
            >
              <span
                className="border border-primary text-danger d-flex align-items-center justify-content-center"
                style={{ width: 37, height: 37 }}
              >
                {'X )'}
              </span>
              <span style={{ maxWidth: 200 }}>
                {t(`${prefix}.legendExclusive`)}
              </span>
            </div>
          </div>
          <Table
            data={records}
            columns={columns}
            isLoadingResults={isLoading}
          />
          {records.length < maxRecords && (
            <div
              className="w-100 d-flex flex-wrap justify-content-center mt-5"
              style={{ gap: 5 }}
            >
              <Button
                text={t(`${prefix}.addRange`)}
                type="button"
                size="md"
                outlined
                className="w-100 mx-auto"
                style={{ maxWidth: 446 }}
                onClick={openRecordModal}
              />
            </div>
          )}
        </>
      )}
      <QualitativeAssessmentRangeModal
        ref={qualitativeAssessmentRangeModalRef}
        onAddRecord={append}
      />
    </>
  );
};

export default forwardRef(QualitativeAssessmentRangeSection);
