import { Button, Modal, TextInput } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../../locales/translations';
import { CourseLearningResult } from '../../types';

type ModalAddCompatibilityProps = {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: (courseLearningResult: CourseLearningResult) => void;
};

const ModalAddCourseLearning = ({
  isOpen,
  toggle,
  onConfirm,
}: ModalAddCompatibilityProps) => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const [courseLearningResult, setCourseLearningResult] = useState<
    CourseLearningResult | undefined
  >(undefined);

  const onClose = () => {
    setCourseLearningResult(undefined);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" unmountOnClose>
      <Row>
        <Col xs={12}>
          <p className="fs-22 text-dark text-center mb-4">
            {t(`modalLearningResultTitle`)}
          </p>
        </Col>
        <Col xs={6}>
          <TextInput
            name="code"
            label={t(`modalLearningResultCode`)}
            value={courseLearningResult?.code}
            onChange={(e) =>
              setCourseLearningResult((prev) => {
                return { ...prev, code: e.target.value, canEdit: false };
              })
            }
          />
        </Col>
        <Col xs={6}>
          <TextInput
            name="description"
            label={t(`modalLearningResultDescription`)}
            value={courseLearningResult?.description}
            onChange={(e) =>
              setCourseLearningResult((prev) => {
                return { ...prev, description: e.target.value };
              })
            }
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} lg={6} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={onClose}
            text={t(`modalCompatibilityCancel`)}
            fullwidth
          />
        </Col>
        <Col xs={12} lg={6} className="pb-2 order-1 order-lg-2">
          <Button
            text={t(`modalLearningResultSave`)}
            onClick={() => {
              courseLearningResult && onConfirm(courseLearningResult);
              setCourseLearningResult(undefined);
              toggle();
            }}
            fullwidth
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalAddCourseLearning;
