export enum TranslationsKeys {
  // Globals
  TERMS = 'terms',
  COMMON = 'common',
  INPUTS = 'inputs',
  // Layouts
  AUTHORIZED_LAYOUT = 'layouts/authorized',
  // Olds
  OLD_TRANSLATIONS = 'translation',
  // academic-offer
  ACADEMIC_OFFER_COURSES_LIST = 'academic-offer/courses/list',
  ACADEMIC_OFFER_COURSES_UPDATE = 'academic-offer/courses/update',
  // configuration
  CONFIGURATION_ACADEMIC = 'configuration/academic',
  CONFIGURATION_FINANTIAL = 'configuration/finantial',
  CONFIGURATION_GRADING_STRUCTURE = 'configuration/grading-structure',
  CONFIGURATION_TABS = 'configuration/tabs',
  // teacher-management
  TEACHER_MANAGMENT_TEACHER_ACTIVATION = 'teacher-management/teacher-activation',
  TEACHER_MANAGMENT_TEACHER_FILE = 'teacher-management/teacher-file',
  TEACHER_MANAGMENT_TEACHER_FILE_LIST = 'teacher-management/teacher-file/list',
  TEACHER_MANAGMENT_TEACHER_FILE_ADDITIONAL_DOCUMENTATION = 'teacher-management/teacher-file/additional-documentation',
  TEACHER_MANAGMENT_TEACHER_FILE_EDUCATIONAL_BACKGROUND = 'teacher-management/teacher-file/educational-background',
  TEACHER_MANAGMENT_TEACHER_FILE_EMPLOYMENT_BACKGROUND = 'teacher-management/teacher-file/employment-background',
  TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION = 'teacher-management/teacher-file/hiring-information',
  TEACHER_MANAGMENT_TEACHER_FILE_PERSONAL_BACKGROUND = 'teacher-management/teacher-file/personal-background',
  TEACHER_MANAGMENT_TEACHER_FILE_PERSONAL_BACKGROUND_REFORMADA = 'teacher-management/teacher-file/personal-background-reformada',
  // tuition-continuity
  TC_DISCOUNT_MANAGEMENT = 'tuition-continuity/discount-allocation-management',
}

export const TranslatonsArray = Object.values(TranslationsKeys);
