import { addToast, TextInput } from '@octano/global-ui';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  Ref,
} from 'react';
import { useTranslation } from 'react-i18next';
import { updateTimeVariableConfig } from '../../../../api/requests/settings';
import { TranslationsKeys } from '../../../../locales/translations';
import { CommonSectionMethods } from '../interfaces/common-section';
import useTimeVariableConfig from './useTimeVariableConfig';

const prefix = 'timeVariable';

// Tiempo maximo y minimo permitido (espejo con validacion de back)
type TimeVariableSectionProps = {
  minTime?: number;
  maxTime?: number;
};

const TimeVariableSection = (
  { minTime = 0, maxTime = 100 }: TimeVariableSectionProps,
  ref: Ref<CommonSectionMethods>,
) => {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);

  const { isLoading, config, refetch } = useTimeVariableConfig();
  const [value, setValue] = useState<number>();
  const [error, setError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const updateVariable = useCallback(
    async (newValue: number) => {
      setIsUpdating(true);

      const { error: updateError } = await updateTimeVariableConfig(newValue);

      if (updateError) {
        addToast({
          icon: 'error',
          text: t(`${prefix}.errorSavingChanges`),
          color: 'danger',
        });
      } else {
        // Actualizamos config
        await refetch();

        addToast({
          icon: 'success',
          text: t(`${prefix}.changesSaved`),
          color: 'success',
        });
      }

      setIsUpdating(false);
    },
    [t, refetch],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    setValue(inputValue);

    if (isNaN(inputValue) || inputValue < minTime || inputValue > maxTime) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleSave = useCallback(async () => {
    if (value === undefined) {
      return;
    }
    await updateVariable(value);
  }, [value, updateVariable]);

  useImperativeHandle(ref, () => ({
    save: handleSave,
    validate: async () => {},
  }));

  // Sincronizamos el valor del input con lo que recibimos del backend
  useEffect(() => {
    if (config) {
      setValue(config.value);
    }
  }, [config, setValue]);

  return (
    <>
      <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3">
        {t(`${prefix}.title`)}
      </h2>
      <p className="fs-14 text-medium mb-3">{t(`${prefix}.description`)}</p>
      <TextInput
        name="timeVariable"
        label={t(`${prefix}.inputLabel`)}
        disabled={isLoading || isUpdating}
        value={value}
        onChange={handleChange}
        errorText={
          error
            ? t(`${prefix}.invalidInput`, { min: minTime, max: maxTime })
            : undefined
        }
      />
    </>
  );
};

export default forwardRef(TimeVariableSection);
