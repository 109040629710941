import { addToast, Select, SelectOptionType } from '@octano/global-ui';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
  Ref,
} from 'react';
import { useTranslation } from 'react-i18next';
import { updateCoursesFeaturesConfig } from '../../../../api/requests/settings';
import { TranslationsKeys } from '../../../../locales/translations';
import { useCoursesFeaturesConfig } from '../hooks/useCoursesFeaturesConfig';
import { CommonSectionMethods } from '../interfaces/common-section';
import { CoursesFeaturesOptions } from '../interfaces/courses-features.interface';

enum RetakeExamAllowedCode {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

type CoursesFeaturesProps = {
  prefix?: string;
};

const CoursesFeatures = (
  { prefix = 'coursesFeatures' }: CoursesFeaturesProps,
  ref: Ref<CommonSectionMethods>,
) => {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);

  const { isLoading, config, refetch } = useCoursesFeaturesConfig();
  const [isUpdating, setIsUpdating] = useState(false);

  const retakeExamOptions = useMemo<SelectOptionType[]>(
    () => [
      {
        label: t(
          `${prefix}.retakeExamAllowed.${RetakeExamAllowedCode.Disabled}`,
        ),
        value: RetakeExamAllowedCode.Disabled,
      },
      {
        label: t(
          `${prefix}.retakeExamAllowed.${RetakeExamAllowedCode.Enabled}`,
        ),
        value: RetakeExamAllowedCode.Enabled,
      },
    ],
    [t, prefix],
  );

  const [retakeExamAlowedValue, setRetakeExamAlowedValue] =
    useState<SelectOptionType>(retakeExamOptions[0]);

  const updateSettings = useCallback(
    async (data: CoursesFeaturesOptions) => {
      setIsUpdating(true);

      const { error: updateError } = await updateCoursesFeaturesConfig(data);

      if (updateError) {
        addToast({
          icon: 'error',
          text: t(`${prefix}.errorSavingChanges`),
          color: 'danger',
        });
      } else {
        // Actualizamos config
        await refetch();

        addToast({
          icon: 'success',
          text: t(`${prefix}.changesSaved`),
          color: 'success',
        });
      }

      setIsUpdating(false);
    },
    [t, refetch, prefix],
  );

  const handleChange = useCallback(
    (next: SelectOptionType) => {
      if (retakeExamAlowedValue?.value !== next?.value) {
        setRetakeExamAlowedValue(next);
      }
    },
    [retakeExamAlowedValue],
  );

  const handleSave = useCallback(async () => {
    await updateSettings({
      retakeExamAllowed:
        retakeExamAlowedValue?.value === RetakeExamAllowedCode.Enabled,
    });
  }, [retakeExamAlowedValue, updateSettings]);

  useImperativeHandle(ref, () => ({
    save: handleSave,
    validate: async () => {},
  }));

  // Sincronizamos el valor del input con lo que recibimos del backend
  useEffect(() => {
    if (config) {
      setRetakeExamAlowedValue(
        config?.retakeExamAllowed ? retakeExamOptions[1] : retakeExamOptions[0],
      );
    }
  }, [config, retakeExamOptions]);

  return (
    <>
      <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3 mt-5">
        {t(`${prefix}.title`)}
      </h2>
      <Select
        value={retakeExamAlowedValue}
        name="retakeExamAllowed"
        label={t(`${prefix}.retakeExamAllowed.label`)}
        options={retakeExamOptions}
        isClearable={false}
        disabled={isLoading || isUpdating}
        onChange={handleChange}
      />
    </>
  );
};

export default forwardRef(CoursesFeatures);
