import { SidenavCategoryType, SidenavLinkType } from '@octano/global-ui';

import { PermissionName } from '../types/Auth';
import { SHOW_PAYMENT_HUB } from './constants';

export interface AllowedLink {
  disabled?: boolean;
}

export interface WithPermissions {
  requiredPermissions?: PermissionName[];
  allPermisionsRequired?: boolean;
}

export type LinkWithPermissions = SidenavLinkType &
  WithPermissions &
  AllowedLink;

export type SubLinkWithPermissions = SidenavCategoryType['links'][number] &
  WithPermissions &
  AllowedLink;

export type CategoryWithPermissions = Omit<SidenavCategoryType, 'links'> & {
  links: SubLinkWithPermissions[];
};

export type SidenavLinkWithPermission =
  | LinkWithPermissions
  | CategoryWithPermissions;

const purgeLinks = (
  links: SidenavLinkWithPermission[],
): SidenavLinkWithPermission[] => {
  links = links.filter((mainLink: any) => {
    return !mainLink?.disabled;
  });
  links = links.map((mainLink: any) => ({
    ...mainLink,
    links: mainLink?.links?.filter((subLink: any) => !subLink?.disabled),
  }));
  return links;
};

export const SIDENAV_LINKS: SidenavLinkWithPermission[] = purgeLinks([
  {
    icon: 'maintainer',
    name: 'sidenav.maintainers',
    links: [
      {
        name: 'sidenav.maintainerPeriods',
        path: '/maintainers/periods',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'sidenav.maintainerCampuses',
        path: '/maintainers/campus',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'sidenav.maintainerSchools',
        path: '/maintainers/schools',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'sidenav.maintainerSchedules',
        path: '/maintainers/schedules',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'sidenav.maintainerModalities',
        path: '/maintainers/modalities',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
    ],
  },
  {
    icon: 'academic_offer',
    name: 'sidenav.academicOffer',
    links: [
      {
        name: 'sidenav.academicOfferCurricularSubjects',
        path: '/academic-offers/curricular-subjects',
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_CURRICULAR_SUBJECT],
      },
      {
        name: 'sidenav.academicOfferStudyPlans',
        path: '/academic-offers/study-plans',
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        name: 'sidenav.academicOfferInterimDegree',
        path: '/academic-offers/interim-degree/curriculums',
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE],
      },
      {
        name: 'sidenav.academicOfferVacantOffers',
        path: '/academic-offers/vacant-offers',
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_VACANT_OFFER],
      },
    ],
  },
  {
    icon: 'bank_statement',
    name: 'sidenav.currentAccount',
    links: [
      {
        name: 'sidenav.currentAccountStudent',
        path: '/current-account',
        requiredPermissions: [PermissionName.CURRENT_ACCOUNT],
      },
    ],
  },
  {
    icon: 'admision',
    name: 'sidenav.enrollment',
    links: [
      {
        name: 'sidenav.enrollmentFees',
        path: '/tariff-tuiton',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_TARIFFS_AND_DISCOUNTS,
        ],
      },
      {
        name: 'sidenav.enrollmentPresential',
        path: '/tuition-process',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS,
        ],
      },
      {
        name: 'sidenav.enrollmentManagment',
        path: '/admissions',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_ADMISSION_MANAGEMENT,
        ],
      },
      {
        name: 'sidenav.enrollmentDocuments',
        path: '/documents',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_DOCUMENTATION_REVIEW_PROCESS,
        ],
      },
    ],
  },
  {
    icon: 'tuiton_continuity',
    name: 'sidenav.enrollmentContinuity',
    links: [
      {
        name: 'sidenav.enrollmentContinuityFees',
        path: '/tuition-continuity-tariff-tuition',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_TARIFF_AND_TUITION,
        ],
      },
      {
        name: 'sidenav.enrollmentContinuityManagment',
        path: '/tuition-continuity-process-management',
        requiredPermissions: [PermissionName.TUITION_CONTINUITY_MANAGEMENT],
      },
      {
        name: 'sidenav.enrollmentContinuityDocuments',
        path: '/tuition-continuity-document-review',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_DOCUMENT_REVIEW,
        ],
      },
      {
        name: 'sidenav.enrollmentContinuityMassiveDiscounts',
        path: '/tuition-continuity-discount-allocation',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_DISCOUNT_ALLOCATION,
        ],
      },
      {
        name: 'sidenav.enrollmentContinuityManagementDiscounts',
        path: '/tuition-continuity-discount-management',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_DISCOUNT_MANAGEMENT,
        ],
      },
    ],
  },
  {
    icon: 'packages',
    name: 'sidenav.sections',
    links: [
      {
        name: 'sidenav.sectionsCoursesRegistration',
        path: '/course-registration',
        requiredPermissions: [PermissionName.ENROLLMENT_MODIFICATION],
      },
      {
        name: 'sidenav.sectionsSectionsMaintainer',
        path: '/sections-maintainer',
        requiredPermissions: [PermissionName.SECTIONS_MODIFICATION],
      },
      {
        name: 'sidenav.sectionsPackages',
        path: '/packages',
        requiredPermissions: [PermissionName.SECTIONS_PACKAGES_ALL],
      },
      {
        name: 'sidenav.sectionsPackagesMaintainer',
        path: '/packages-maintainer',
        requiredPermissions: [PermissionName.SECTION_PACKAGES_LOAD_ALL],
      },
      {
        name: 'sidenav.sectionsAttributes',
        path: '/section-attributes',
        requiredPermissions: [PermissionName.SECTION_PACKAGES_LOAD_ALL],
      },
      {
        name: 'sidenav.sectionsClosing',
        path: '/closing-period',
        requiredPermissions: [PermissionName.SUBJECT_SECTIONS_PERIOD_CLOSE],
      },
      {
        name: 'sidenav.sectionsStudentsPostClose',
        path: '/students-post-close',
        requiredPermissions: [PermissionName.STUDENTS_POST_CLOSE],
      },
    ],
  },
  {
    icon: 'identification',
    name: 'sidenav.managmentTootls',
    links: [
      {
        name: 'sidenav.managmentTootlsReports',
        path: '/reports',
        requiredPermissions: [PermissionName.REPORTS],
      },
      {
        name: 'sidenav.managmentTootlsUsersAndRoles',
        path: '/users-and-roles',
        requiredPermissions: [PermissionName.MANAGEMENT_TOOLS_USERS_AND_ROLES],
      },
    ],
  },
  {
    icon: 'person_record',
    name: 'sidenav.studentManagment',
    links: [
      {
        name: 'sidenav.studentManagmentStudentStatus',
        path: '/student-status',
        requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_STUDENT_STATUS],
      },
      {
        name: 'sidenav.studentManagmentStudentFile',
        path: '/student-file',
        requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_STUDENT_FILE],
      },
      {
        name: 'sidenav.studentManagmentCurriculumProgress',
        path: '/avance-curricular',
        requiredPermissions: [PermissionName.STUDENT_CURRICULUM_PROGRESS_LIST],
      },
      {
        name: 'sidenav.studentManagmentStudyPlanChange',
        path: '/enrollment-soft-change',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_ENROLLMENT_SOFT_CHANGE,
        ],
      },
      {
        name: 'sidenav.studentManagmentPetitions',
        path: '/student-petitions',
        requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_PETITIONS],
      },
      {
        name: 'sidenav.studentManagmentGraduationProcessMassive',
        path: '/graduation-process-massive',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_GRADUATION_PROCESS,
        ],
      },
      {
        name: 'sidenav.studentManagmentGraduationProcessSingle',
        path: '/graduation-process-single',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_GRADUATION_PROCESS,
        ],
      },
      {
        name: 'sidenav.studentManagmentGraduatesList',
        path: '/graduates-list',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_GRADUATION_PROCESS,
        ],
      },
      {
        name: 'sidenav.studentManagmentStudentsDegree',
        path: '/students-degree',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_STUDENTS_DEGREE,
        ],
      },
    ],
  },
  {
    icon: 'teacher',
    name: 'sidenav.teacherManagment',
    links: [
      {
        name: 'sidenav.teacherManagmentActivation',
        path: '/teachers-activation',
        requiredPermissions: [PermissionName.TEACHER_MANAGEMENT_ALL],
      },
      {
        name: 'sidenav.teacherManagmentFile',
        path: '/teacher-file',
        requiredPermissions: [PermissionName.TEACHER_MANAGEMENT_ALL],
      },
    ],
  },
  {
    icon: 'certification',
    name: 'sidenav.interimDegree',
    links: [
      {
        name: 'sidenav.interimDegreeGraduationProcessMassive',
        path: '/interim-degree-graduation-process/massive',
        requiredPermissions: [
          PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
        ],
      },
      {
        name: 'sidenav.interimDegreeGraduationProcessSingle',
        path: '/interim-degree-graduation-process/single',
        requiredPermissions: [
          PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
        ],
      },
      {
        name: 'sidenav.interimDegreeGraduationProcessStudents',
        path: '/interim-degree-graduation-process/students',
        requiredPermissions: [
          PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
        ],
      },
    ],
  },
  {
    icon: 'course_validation',
    name: 'sidenav.degreeRecognition',
    links: [
      {
        name: 'sidenav.degreeRecognitionInternal',
        path: '/convalidacion-interna',
        requiredPermissions: [PermissionName.COURSE_VALIDATION],
      },
    ],
  },
  {
    icon: 'pay_module',
    name: 'sidenav.paymentHub',
    path: '/centro-pagos',
    disabled: !SHOW_PAYMENT_HUB,
    requiredPermissions: [PermissionName.PAYMENT_HUB],
  },
  {
    icon: 'power',
    name: 'sidenav.initialLoadUp',
    links: [
      {
        name: 'sidenav.initialLoadUpAcademicRecord',
        path: '/record-academico',
        requiredPermissions: [PermissionName.ACADEMIC_RECORD],
      },
    ],
  },
  {
    icon: 'settings',
    name: 'sidenav.settings',
    links: [
      {
        name: 'sidenav.settingsAcademics',
        path: '/config?tab=academics',
        requiredPermissions: [PermissionName.SETTINGS],
      },
      {
        name: 'sidenav.settingsFinantial',
        path: '/config?tab=finantial',
        requiredPermissions: [PermissionName.SETTINGS],
      },
      {
        name: 'sidenav.settingsGradingStructure',
        path: '/config?tab=gradingStructure',
        requiredPermissions: [PermissionName.SETTINGS],
      },
    ],
  },
]);
